import {
  BannerCard,
  BannerCards,
  EBannerCardBackground,
  EBannerCardLayout,
  ETagSize,
  ETagTheme,
  ETagVariant,
  ETitleSize,
  RichText,
  toImageProps,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type TBannerCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'banner-cards' }
>;

const BannerCardsConnected: React.FC<TBannerCardsConnectedProps> = props => {
  const {
    cards = [],
    showAs,
    showNumbersBeforeTabName = true,
    tabStyle,
  } = props;
  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');

  const banners: React.ComponentProps<typeof BannerCard>[] = useMemo(() => {
    return cards.map(card => ({
      ...card,
      icon: toImageProps(card.icon),
      description: <RichText content={card.description} />,
      image: toImageProps(card?.banner?.media),
      swapImageSide: card?.banner?.swapImageSide,
      tags: card.tags?.map(tag => ({
        children: tag.text,
      })),
      bullets: {
        bulletStroke: card.bullets?.bulletStroke,
        items: card.bullets?.items?.map((item, i) => (
          <RichText key={i} content={item.text} />
        )),
      },
      imageRow: {
        text: card.imageRow?.text,
        iconPosition: card.imageRow?.iconPosition,
        icon: toImageProps(card.imageRow?.icon),
        images:
          card.imageRow?.images?.map(image => {
            return toImageProps(image.media);
          }) || [],
      },
      badges: card.badges?.map(badge => ({
        children: badge.text,
      })),
      badgesStyle: card.badgesStyle as ETagVariant,
      badgesSize: card.badgesSize as ETagSize,
      badgesTheme: card.badgesTheme as ETagTheme,
      buttons: toCardButtons(card.buttons, currentLocale, appDispatch),
      size: card.size as EBannerCardLayout,
      titleSize: card.titleSize as ETitleSize,
      background: card.background as EBannerCardBackground,
      addons: {
        downloads: {
          appStoreUrl: card.downloads?.appStore,
          googlePlayUrl: card.downloads?.googlePlay,
        },
      },
    }));
  }, [cards, currentLocale, appDispatch]);

  return (
    <BannerCards
      banners={banners}
      showAs={showAs}
      tabStyle={tabStyle}
      showNumbersBeforeTabName={showNumbersBeforeTabName}
    />
  );
};

export default BannerCardsConnected;
